import styled from 'styled-components';

const ProjectItem = styled.article`
  margin-bottom: 2rem;

  .gatsby-image-wrapper {
    width: 100%;
    height: 300px;
  }

  .body {
    display: flex;
    min-height: 142px;
    flex-direction: column;
    justify-content: space-between;
    time {
      display: block;
      margin-top: 1.5rem;
      font-family: var(--fontSecondary);
      font-size: 1.4rem;
      opacity: 0.7;
    }

    p {
      width: 100%;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    a {
      font-family: var(--fontSecondary);
      color: var(--black);
      h2 {
        margin: 0 0 0.5rem;
        font-size: 2rem;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  @media (max-width: 767.98px) {
    .gatsby-image-wrapper {
      height: 300px;
    }
  }
`;

export default ProjectItem;
