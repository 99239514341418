import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { format } from 'date-fns';
import CategoryList from '../components/CategoryList';
import SEO from '../components/SEO';
import Container from '../styles/Container';
import PageHeading from '../styles/PageHeading';
import ProjectItem from '../styles/ProjectItem';

export const query = graphql`
  query {
    projects: allSanityProject {
      nodes {
        id
        slug {
          current
        }
        title
        publishedAt
        mainImage {
          asset {
            fluid(maxHeight: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
        categories {
          id
          title
          colorHex
        }
        body
        excerpt
      }
    }
  }
`;

const ProjectListing = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
  gap: 4.5rem;

  @media (max-width: 767.98px) {
    grid-template-columns: 1fr;
  }
`;

const Projects = ({ data }) => (
  <>
    <SEO title="💻 Stuff I've done" />
    <Container>
      <PageHeading>Projects</PageHeading>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem
        earum laborum iure dicta blanditiis quas illum adipisci mollitia totam?
        Labore, quaerat adipisci iste corporis minima porro sunt error laborum
        ad
      </p>
    </Container>
    <Container>
      <ProjectListing>
        {data.projects.nodes.map(project => (
          <ProjectItem key={project.id}>
            <Link to={`/projects/${project.slug.current}`}>
              <Img fluid={project.mainImage?.asset?.fluid} />
            </Link>
            <div className="body">
              <div>
                <time>
                  {format(new Date(project.publishedAt), 'MMMM do, yyyy')}
                </time>
                <Link to={`/projects/${project.slug.current}`}>
                  <h2>{project.title}</h2>
                </Link>
              </div>
              <p>{project.excerpt}</p>
              <CategoryList categories={project.categories} />
            </div>
          </ProjectItem>
        ))}
      </ProjectListing>
    </Container>
  </>
);

export default Projects;
